<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="500" transition="dialog-bottom-transition">
            <v-card :color="'#15181E'" class="model-card text-center">
                <v-row justify="space-between" style="width: 100%">
                    <v-col lg="10">
                        <div :class="'general-text-title model-title'">
                            Mint Asset
                        </div>
                    </v-col>
                    <v-col lg="2" class="mt-5">
                        <v-icon color="white" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="sub-title mt-5">
                    {{ isConnected ? '' : "You are not connected to any wallet.Click below to connect your wallet or send a mint request to Signa X Team.We will help you to mint your asset" }}
                </div>
                <div v-if="isConnected" style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                    <p class="sub-title">Enter Price</p>
                    <v-text-field class="text-field-designer" dark solo color="#ff7f3f" height="50"
                        background-color="transparent" v-model="price.price" type="number">
                        <v-icon slot="append" color="#ff7f3f">mdi-ethereum</v-icon>
                    </v-text-field>
                </div>
                <v-row v-if="isConnected">
                    <v-col lg="6">
                        <v-btn class="mt-10 button button-data" @click="mintNow()">Mint Now</v-btn>
                    </v-col>
                    <v-col lg="6">
                        <v-btn class="mt-10 button button-secondary" @click="close()">Cancel</v-btn>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col lg="6">
                        <v-btn class="mt-10 button button-data" @click="mintRequest()">Request to mint</v-btn>
                    </v-col>
                    <!-- TODO:: Make a connect popup -->
                    <v-col lg="6">
                        <v-btn class="mt-10 button button-secondary" @click="connectWallet()">Connect Wallet</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "MintModal",
    props: ["visible","price"],
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) return this.$emit("close");
            },
        },
        ...mapState({
            isConnected: (state) => state.isConnected,
        }),
    },
    methods: {
        close() {
            this.$emit("close");
        },
        hide() {
            this.$store.dispatch("setHideWarning", true);
            this.$emit("close");
        },
        mintRequest() {
            this.$emit('mint-request')
        },
        mintNow() {
            this.$emit('mint-now')
        },
        connectWallet(){
             this.$emit("close");
            this.$router.push('/wallet');
        }
    },
};
</script>
<style scoped>
.model-card {
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.sub-title {
    color: rgba(196, 196, 196, 0.5);
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
}

.button-data {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
}

.button-secondary {
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
    border: 1px solid var(--border-color);
    background: transparent !important;
}

.model-title {
    font-size: 40px !important;
    text-align: left;
}

.text-field-designer {
    width: 100%;
    height: 55px;
    background: rgba(196, 196, 196, 0.1) !important;
    box-shadow: none !important;
    border-radius: 5px;
    border: 1px solid #eb5129 !important;
    outline: none;
    color: #fff !important;
    font-family: "Archivo", sans-serif;
}
</style>
