<template>
    <div>
        <v-dialog v-model="visible" max-width="500" transition="dialog-bottom-transition">
            <v-card :color="'#15181E'" class="model-card text-center">
                <v-row justify="space-between" style="width: 100%">
                    <v-col lg="10">
                        <div :class="'general-text-title model-title'">
                            Ask Queries
                        </div>
                    </v-col>
                    <v-col lg="2" class="mt-5">
                        <v-icon color="white" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="sub-title mt-5">
                    You can ask any queries regarding this asset. We will get back to you at the earliest.
                </div>
                <textarea rows="5" v-model="message" class="mt-2"></textarea>
                <v-btn class="mt-10 button button-data" @click="sendMessage()" :disabled="message.length === 0">Submit</v-btn>
            </v-card>
        </v-dialog>
        <Loader :visible="isLoading" :text="loaderText"></Loader>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { ADMIN_USER_ID, NOTIFICATION_TYPE_CONTACT_CHAT } from "../../helpers/constants";
import { contactObj } from "../../services/contact-service";
import { mailServiceObj } from "../../services/mail-service";
import { notificationObj } from "../../services/notification-service";
import Loader from "../Loader/Loader.vue";

export default {
    name: "AskQueryModal",
    props: ["visible", "tokenDetails"],
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async sendMessage() {
            try {
                this.loaderText = "Submitting....";
                this.isLoading = true;
                const contactMessage = await contactObj.getMessagesFromUserId(this.user.uid);
                const messages = [];
                if (contactMessage.length >= 1) {
                    for (let chatIndex = 0; chatIndex < contactMessage.length; chatIndex++) {
                        const chatElement = contactMessage[chatIndex].messages;
                        for (let i = 0; i < chatElement.length; i++) {
                            const element = chatElement[i];
                            messages.push(element);
                        }
                    }
                }
                messages.push({
                    message: `${this.message}`,
                    type: "user",
                    user: this.user,
                });
                const jsonData = {
                    messages: messages,
                    userId: this.user.uid,
                    sentTime: new Date(),
                };
                await contactObj.sendMessage(jsonData);
                const notificationData = {
                    title: `${this.user.name} just messaged you`,
                    message: `Tap here to know more...`,
                    meta: {
                        request_id: '',
                        chat: true,
                    },
                };
                notificationObj.sendNotification(
                    notificationData,
                    this.user.uid,
                    ADMIN_USER_ID,
                    NOTIFICATION_TYPE_CONTACT_CHAT
                );
                await mailServiceObj.sendMailToUserForContact(this.user);
                this.isLoading = false;
                this.$emit('close');
            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }
        }
    },
    data() {
        return {
            message: "",
            isLoading: false,
            loaderText: "",
        };
    },
    components: { Loader }
};
</script>
<style scoped>
.model-card {
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-title {
    color: rgba(196, 196, 196, 0.5);
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
}

.button-data {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
}

.model-title {
    font-size: 40px !important;
    text-align: left;
}

textarea {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    color: white;
    outline: none;
    padding: 10px;
}
</style>
