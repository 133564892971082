<template>
  <div :style="showWarning ? { filter: 'blur(10px)' } : { filter: 'none' }">
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="500"
    >
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Send Automation Mail</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">
                <v-icon color="white">mdi-close</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-content mt-10 mb-10">
          <div class="form-group">
            <p>Metaverse name</p>
            <input v-model="name" />
            <p>
              The email will contain the name of the Metaverse World added here.
            </p>
          </div>
        </div>
        <div class="card-footer">
          <v-btn class="btn" @click="warningData()" :disabled="name.length === 0 || name.trim().length === 0">Send Mail</v-btn>
        </div>
      </div>
    </v-dialog>
    <loader-modal :text="loaderText" :visible="showLoader"></loader-modal>
    <v-dialog
      v-model="showWarning"
      persistent
      max-width="500"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card :color="'#15181E'" class="model-card text-center dialog">
        <v-row justify="space-between" style="width: 100%">
          <v-col lg="12">
            <div :class="'general-text-title model-title'">
              Are you sure you want to send email?
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-around;">
          <v-col lg="6">
            <v-btn class="mt-10 button button-data" @click="submitMail()"
              >Yes</v-btn
            >
          </v-col>
          <v-col lg="6">
            <v-btn
              class="mt-10 button button-data-secondary"
              @click="removeWarning()"
              >No</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import LoaderModal from "../Loader/Loader.vue";
import { mailServiceObj } from "../../services/mail-service";

export default {
  name: "AutomationEmailModal",
  props: ["visible", "selectedAsset"],
  components: { LoaderModal },
  data() {
    return {
      name: "",
      showLoader: false,
      loaderText: "",
      showWarning: false,
    };
  },
  methods: {
    warningData() {
      this.showWarning = true;
    },
    removeWarning() {
      this.showWarning = false;
    },
    async submitMail() {
      this.loaderText = "Sending Mail... "+this.selectedAsset.user;
      this.showLoader = true;
      console.log(this.selectedAsset.user);
      await mailServiceObj.sendAutomationEmailToCreatorOnPublish(selectedAsset.user, name);
      setTimeout(() => {
        this.showLoader = false;
        this.showWarning = false;
        this.close();
      }, 3000);
    },
    close() {
      this.$emit("close");
    },
    openLoader() {
      this.showLoader = true;
    },
  },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-size: 30px;
  font-weight: 500;
}
.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}
.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 5px !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  width: 30%;
  margin-top: 10px;
  color: white;
  font-size: 22px;
  letter-spacing: 0.8px;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.button-data {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}
.model-title {
  font-size: 20px !important;
  text-align: center;
}

.button-data-secondary {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}
.btn:disabled{
    cursor: not-allowed;
    background-color: grey;
}
</style>
