<template>
  <div class="preview-token" style="padding-top: 5%">
    <div class="preview-body mt-10 mb-10 ml-10" v-if="tokenDetails !== null">
      <div
        class="tool-bar mb-5 mt-10"
        v-if="
          (tokenDetails.userId === user.uid || user.role === 'team') &&
          this.$route.query.type !== 'chain'
        "
      >
        <v-row style="width: 90%">
          <v-col cols="12" sm="12" lg="3" style="padding-left: 0%">
            <v-btn class="ml-5 btn-secondary" @click="goTo()">
              <v-icon size="20">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col :lg="tokenDetails.image.length != 0 ? 3 : 4" cols="12" sm="12">
          </v-col>
          <v-col
            cols="12"
            sm="12"
            :lg="tokenDetails.image.length !== 0 ? 5 : 4"
            class="ml-10"
          >
            <v-btn class="ml-5 btn-secondary" @click="showEditPopup = true">
              <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="btn-secondary" @click="showWarning = true">
              <v-icon size="20">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              class="btn-secondary"
              @click="copyToClipBoard(tokenDetails.tokenId)"
            >
              <v-icon size="20">mdi-share-variant-outline</v-icon>
            </v-btn>
            <v-btn
              class="btn-secondary"
              @click="download(tokenDetails.image)"
              v-if="tokenDetails.image.length !== 0 && user.role === 'team'"
            >
              <v-icon size="20">mdi-download</v-icon>
            </v-btn>
            <v-btn
              class="btn-secondary"
              v-if="tokenDetails.image.length !== 0 && user.role === 'team'"
              @click="showAutomationEmailMethod()"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20" v-bind="attrs" v-on="on">mdi-wan</v-icon>
                </template>
                <span>Send Automation Email</span>
              </v-tooltip>
            </v-btn>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn ml-5"
                  @click="editPriceModal = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">mdi-currency-usd</v-icon>
                </v-btn> </template
              ><span>Quote Price</span></v-tooltip
            > -->
          </v-col>
        </v-row>
      </div>
      <v-row style="width: 100%">
        <v-col cols="12" sm="12" lg="11">
          <v-row>
            <v-col cols="12" sm="12" lg="6">
              <div class="general-text-title request-title">
                {{ tokenDetails.name }}
              </div>
              <div class="request-description">
                {{ tokenDetails.description }}
              </div>
              <div class="profile-store">
                <div class="profile-image">
                  <img :src="tokenDetails.user.profile" />
                </div>
                <div class="details ml-2">
                  <div class="name">Creator</div>
                  <div
                    class="username"
                    @click="checkProfile()"
                    v-if="user.role !== 'team'"
                  >
                    {{ tokenDetails.user.uid }}
                  </div>
                  <div class="username" @click="checkProfile()" v-else>
                    {{ tokenDetails.user.name }}
                  </div>
                </div>
              </div>
              <v-tooltip bottom v-if="user.role === 'designer'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="btn-quote"
                    @click="editPriceModal = true"
                    v-bind="attrs"
                    v-on="on"
                    style="margin-top: 5%; margin-bottom: 5%"
                  >
                    <v-icon size="20">mdi-currency-usd</v-icon>
                    Quote Price
                  </v-btn> </template
                ><span>Quote Price</span></v-tooltip
              >
              <div
                class="request-asset-nature"
                style="margin-top: 10px !important"
              >
                <p>Asset Quotation</p>
                <v-row>
                  <v-col lg="3">
                    <div class="asset-nature">
                      <v-icon color="#ff7f3f" size="20"
                        >mdi-currency-usd</v-icon
                      >
                      <p style="font-size: 16px !important">
                        {{
                          tokenDetails.price !== 0 &&
                          tokenDetails.price.length != 0
                            ? tokenDetails.price
                            : 0
                        }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="request-asset-nature mt-5">
                <p>Asset Environment</p>
                <v-row>
                  <v-col
                    lg="3"
                    v-for="(data, index) in tokenDetails.selectedAssetType"
                    :key="index"
                  >
                    <div class="asset-nature">
                      <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                      <p>{{ data }}</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
              <v-row>
                <v-col lg="8">
                  <div class="request-img">
                    <model-viewer
                      :src="selectedContentType.url"
                      ar-modes="webxr scene-viewer quick-look"
                      seamless-poster
                      shadow-intensity="1"
                      camera-controls
                      ar
                      auto-rotate
                      loading="eager"
                      style="height: 100%; width: 100%"
                      v-if="selectedContentType.type === 'model'"
                    >
                    </model-viewer>
                    <img
                      :src="selectedContentType.url"
                      style="width: 100%; height: 100%; object-fit: contain"
                      v-else-if="selectedContentType.type === 'image'"
                    />
                    <video
                      id="video"
                      controls
                      v-else
                      :key="selectedContentType.url"
                    >
                      <source :src="selectedContentType.url" />
                    </video>
                    <div class="verification-tab">
                      <div class="status">
                        <v-icon
                          v-if="!tokenDetails.verified"
                          color="white"
                          size="15"
                          >mdi-close-circle</v-icon
                        >
                        <v-icon
                          v-if="tokenDetails.verified"
                          color="white"
                          size="15"
                          >mdi-check-decagram</v-icon
                        >
                      </div>
                      <div class="status-text">
                        {{
                          tokenDetails.verified ? "Verified" : "Not Verified"
                        }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col lg="4">
                  <div class="image-list">
                    <div
                      class="image-card mb-2"
                      @click="selectContent(video, 'model')"
                      v-if="tokenDetails.image.length != 0"
                    >
                      <img
                        :src="tokenDetails.placeholder"
                        alt=""
                        class="image-card-image"
                      />
                      <div class="video-card">3D Model</div>
                    </div>
                    <div
                      class="image-card mb-2"
                      @click="selectContent(image, 'image')"
                      v-for="(image, index) in tokenDetails.references"
                      :key="'Image' + index"
                    >
                      <img :src="image" alt="" class="image-card-image" />
                    </div>
                    <div
                      class="image-card mb-2"
                      @click="selectContent(video, 'video')"
                      v-for="(video, index) in tokenDetails.videos"
                      :key="'Video' + index"
                    >
                      <img
                        :src="tokenDetails.placeholder"
                        alt=""
                        class="image-card-image"
                      />
                      <div class="video-card">VIDEO</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="request-asset-nature">
            <p>Asset Categories</p>
            <v-row style="width: 90%">
              <v-col
                lg="3"
                v-for="(cat, index) in tokenDetails.assetCategories"
                :key="index"
              >
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-tag</v-icon>
                  <p>{{ cat }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset File Formats</p>
            <v-row>
              <v-col
                lg="1"
                v-for="(cat, index) in tokenDetails.availableFileFormats"
                :key="index"
              >
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-file-document-outline</v-icon
                  >
                  <p>{{ cat }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" sm="0" lg="4"> </v-col>
      </v-row>
      <div v-if="user.role !== 'team'">
        <div
          class="button-footer-mult mb-10"
          v-if="tokenDetails.user.uid === user.uid"
        >
          <v-row v-if="tokenDetails.verified">
            <v-col lg="6">
              <div
                v-if="tokenDetails.owner === undefined"
                style="display: flex; align-items: center"
              >
                <v-btn class="btn-listed"
                  >Listed
                  <v-icon class="ml-2" size="20">mdi-check-decagram</v-icon>
                </v-btn>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="message-data"
                      v-bind="attrs"
                      v-on="on"
                      @click="showChat = true"
                    >
                      <v-icon class="" size="20" color="white"
                        >mdi-message</v-icon
                      >
                    </div>
                  </template>
                  <span>Ask any queries to the team regarding this assets</span>
                </v-tooltip>
              </div>
              <v-btn
                class="btn"
                @click="leaseAssetToSigna()"
                v-else-if="!tokenDetails.isLent"
                >Lend Asset</v-btn
              >
              <v-btn class="btn" v-else>Asset Leased</v-btn>
            </v-col>
          </v-row>
          <!-- <v-row v-if="tokenDetails.verified && tokenDetails.mintRequest">
            <v-col lg="6">
              <v-btn class="btn">Mint Request Sent</v-btn>
            </v-col>
          </v-row> -->
        </div>
        <div class="button-footer-mult mb-10" v-else>
          <v-row v-if="tokenDetails.owner === undefined">
            <v-col lg=" 6">
              <v-btn class="btn" @click="updateToken()">Request</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col lg="6">
              <v-btn class="btn" @click="updateToken()">Buy Now</v-btn>
            </v-col>
            <v-col lg="6">
              <v-btn class="btn" @click="showPopupForRent()">Lease Now</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else-if="user.role === 'team'">
        <div class="button-footer-mult mb-10">
          <!-- <v-row v-if="tokenDetails.verified && !tokenDetails.mintRequest">
            <v-col lg="6">
              <v-btn class="btn" @click="mintRequest()">Mint Request</v-btn>
            </v-col>
          </v-row> -->
          <v-row v-if="!tokenDetails.verified && !tokenDetails.mintRequest">
            <v-col lg="6">
              <v-btn class="btn" @click="acceptToken()">Accept Asset</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :status="isSuccess"
      :message="statusMessage"
      :title="statusTitle"
      :visible="successPopup"
    >
    </StatusModel>
    <MintModal
      :price="tokenDetails"
      :visible="showMintModal"
      @close="close"
      @mint-request="mintRequest"
      @mint-now="mintNow()"
    >
    </MintModal>
    <RentAssetModal
      :token-details="tokenDetails"
      :visible="showRentModal"
      @close="close"
    ></RentAssetModal>
    <AskAssetQuery
      :visible="showChat"
      :tokenDetails="tokenDetails"
      @close="close"
    ></AskAssetQuery>
    <EditUploadedAsset
      :visible="showEditPopup"
      :tokenDetails="tokenDetails"
      @close="close"
    ></EditUploadedAsset>
    <v-dialog
      v-model="showWarning"
      persistent
      max-width="500"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card :color="'#15181E'" class="model-card text-center dialog">
        <v-row justify="space-between" style="width: 100%">
          <v-col lg="12">
            <div :class="'general-text-title model-title'">
              Are you sure you want to delete?
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col lg="4">
            <v-btn class="mt-10 button button-data" @click="onClickEvent()"
              >Yes</v-btn
            >
          </v-col>
          <v-col lg="4">
            <v-btn class="mt-10 button button-data-secondary" @click="close()"
              >No</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <EditPriceModal
      :visible="editPriceModal"
      @close="close()"
      :tokenDetails="tokenDetails"
    ></EditPriceModal>
    <status-snackbar :text="snackText" :visible="showSnack"></status-snackbar>
    <automation-email-modal :selectedAsset="tokenDetails" :visible="showAutomationEmail" @close="closeAutomationEmailMethod"></automation-email-modal>
  </div>
</template>
<script>
import PropertyCard from "../Common/PropertyCard.vue";
import { mapState } from "vuex";
import Header from "../Header.vue";
import WalletCard from "../Common/WalletCard.vue";
import { assetObj } from "../../services/asset-service";
import { mailServiceObj } from "../../services/mail-service";
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import { contactObj } from "../../services/contact-service";
import MintModal from "../Modals/MintModal.vue";
import { uploadAssetToIPFS } from "../../helpers/ipfs-upload";
import { createUserAssets } from "../../helpers/asset-contract";
import RentAssetModal from "../Modals/RentAssetModal.vue";
import AskAssetQuery from "../Modals/AskAssetQuery.vue";
import EditUploadedAsset from "../Modals/EditUploadedAsset.vue";
import EditPriceModal from "../Modals/UploadAssets/EditPriceModal.vue";
import { userObj } from "../../services/user-service";
import StatusSnackbar from "../Common/StatusSnackbar.vue";
import AutomationEmailModal from "../Modals/AutomationEmailCreator.vue" ;

export default {
  name: "ViewMarketItemUpdate",
  components: {
    PropertyCard,
    Header,
    WalletCard,
    Loader,
    StatusModel,
    MintModal,
    RentAssetModal,
    AskAssetQuery,
    EditUploadedAsset,
    EditPriceModal,
    StatusSnackbar,
    AutomationEmailModal
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isConnected: (state) => state.isConnected,
    }),
  },
  created() {
    this.getTokenDetails();
  },
  data() {
    return {
      tokenDetails: null,
      isLoading: false,
      loaderText: "Verifying Asset....",
      isSuccess: false,
      successPopup: false,
      contractAddress: "",
      walletAddress: "",
      statusTitle: "",
      statusMessage: "",
      showRentModal: false,
      messages: [],
      showMintModal: false,
      price: 0,
      showChat: false,
      showEditPopup: false,
      showWarning: false,
      editPriceModal: false,
      snackText: "You can now share the URL to others",
      showSnack: false,
      selectedContentType: {
        url: "",
        type: "",
      },
      showAutomationEmail:false,
    };
  },
  methods: {
    async getTokenDetails() {
      try {
        this.loaderText = "Loading Asset Details....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        if (this.isConnected && type === "chain") {
          const res = await assetObj.getAssetById(tokenId);
          this.tokenDetails = res;
          this.tokenDetails.verified = res.isVerified;
          this.tokenDetails.assetCategories = res.category;
          this.tokenDetails.fileFormats = res.fileFormats;
          this.tokenDetails.availableFileFormats = res.fileFormats;
        } else {
          const res = await assetObj.getLazyMintById(tokenId);
          this.tokenDetails = res;
          this.tokenDetails.isVerified = res.verified;
          this.tokenDetails.seller = res.user.uid;
          this.tokenDetails.fileFormats = res.availableFileFormats;
        }
        if (
          this.tokenDetails.image != null &&
          this.tokenDetails.image.length != 0
        ) {
          this.selectedContentType = {
            url: this.tokenDetails.image,
            type: "model",
          };
        } else {
          this.selectedContentType = {
            url: this.tokenDetails.placeholder,
            type: "image",
          };
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Fetching Asset Details";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async copyButtonUrl() {
      const url = `${window.location.origin}/creator/asset/${this.tokenDetails.tokenId}`;
      await navigator.clipboard.writeText(url);
    },
    close() {
      this.successPopup = false;
      this.showRentModal = false;
      this.showMintModal = false;
      this.showChat = false;
      this.showEditPopup = false;
      this.showWarning = false;
      this.editPriceModal = false;
    },
    async gotToUserProfile(seller_id) {
      this.$router.push(`/user/${seller_id}`);
    },
    async mintRequest() {
      try {
        this.loaderText = "Sending Minting Request....";
        this.isLoading = true;
        await mailServiceObj.sendMailToAdminOnceNewMintRequestCreated(
          this.user.name
        );
        const tokenId = this.$route.params.tokenId;
        await assetObj.mintRequestStatus(tokenId);
        this.messages.push({
          message: "Needed to mint this asset with the id: " + tokenId + " ",
          type: "user",
          user: this.user,
        });
        const jsonData = {
          messages: this.messages,
          userId: this.user.uid,
          sentTime: new Date(),
        };
        await contactObj.sendMessage(jsonData);
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Mint Request Sent";
        this.statusMessage =
          "Your Mint Request has been sent to the admin. You will be notified once the admin approves your request.";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Sending Mint Request";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    checkProfile() {
      this.$router.push(`/user/${this.tokenDetails.user.uid}`);
    },
    async acceptToken() {
      try {
        this.loaderText = "Accepting Asset....";
        this.isLoading = true;
        // await mailServiceObj.sendMailToAdminOnceNewMintRequestCreated(
        //   this.user.name
        // );
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        if (this.isConnected && type === "chain") {
          await assetObj.verifyUserAsset(tokenId);
        } else {
          await assetObj.updateTokenId(tokenId, this.tokenDetails);
        }
        window.location.reload();
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Accepting Asset....";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async updateToken() {
      try {
        this.loaderText = "Sending Request....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        if (this.tokenDetails.requests === undefined) {
          this.tokenDetails.requests = [
            {
              userId: this.user.uid,
              user: this.user,
            },
          ];
        } else {
          const user = this.tokenDetails.requests.filter(
            (data) => data.userId === this.user.uid
          );
          if (user.length === 0) {
            this.tokenDetails.requests.push({
              userId: this.user.uid,
              user: this.user,
            });
          }
        }
        await assetObj.updateLazyMint(tokenId, this.tokenDetails);
        await mailServiceObj.sendMailToAdminWhenOwnerOfferMade(
          this.tokenDetails,
          this.user
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Asset Request Sent...";
        this.statusMessage =
          "Your Request Have been submitted. The team will get back to you";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
          window.location.reload();
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Sending Request...";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async leaseAssetToSigna() {
      try {
        this.loaderText = "Leasing Asset....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        await assetObj.leaseTokenToSigna(
          tokenId,
          this.tokenDetails.assetAddress
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Asset Leased Successfully";
        this.statusMessage = "";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Asset Leasing Failed";
        this.statusMessage = err.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
    },
    showMintPopup() {
      this.showMintModal = true;
    },
    async mintNow() {
      try {
        this.loaderText = "Minting Asset...";
        this.isLoading = true;
        const url = await uploadAssetToIPFS(this.tokenDetails);
        if (url) {
          const transactionData = await createUserAssets(
            this.tokenDetails,
            url
          );
          if (transactionData) {
            const mintedAsset = await assetObj.getUserNonVerified();
            if (mintedAsset.length > 0) {
              const tokenId = this.$route.params.tokenId;
              await assetObj.deleteDocument(tokenId);
              this.isLoading = false;
              this.tokenDetails.tokenId =
                mintedAsset[mintedAsset.length - 1].tokenId;
              this.statusTitle = "Asset Minted Successful";
              this.statusMessage =
                "You asset minting successful, the team will verify the asset";
              this.isSuccess = true;
              this.successPopup = true;
              setTimeout(() => {
                this.successPopup = false;
              }, 2000);
              this.$router.push("/wallet");
            }
          }
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    showPopupForRent() {
      this.showRentModal = true;
    },
    async onClickEvent() {
      const tokenId = this.$route.params.tokenId;
      const userId = this.tokenDetails.userId;
      let userData = await userObj.getUser(userId);
      let userCollectionData = userData.data();
      let assetsCreated = userCollectionData.assetCreated;
      assetsCreated -= 1;
      userCollectionData.uid = userId;
      userCollectionData.assetCreated = assetsCreated;
      await userObj.updateUserData(userCollectionData);
      await assetObj.deleteDocument(tokenId);
      this.$router.push("/profile?tab=2");
    },
    goTo() {
      this.$router.go(-1);
    },
    async copyToClipBoard(id) {
      const type = this.$route.query.type;
      const url = `${window.location.origin}/asset/public/${id}?type=${type}`;
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = url;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.showSnack = true;
      setTimeout(() => {
        this.showSnack = false;
      }, 2000);
    },
    selectContent(url, type) {
      this.selectedContentType = {
        url,
        type,
      };
    },
    download(href) {
      window.open(href, "_blank");
    },
    showAutomationEmailMethod(){
      this.showAutomationEmail = true;
    },
    closeAutomationEmailMethod(){
      this.showAutomationEmail = false;
    }
  },
};
</script>
<style scoped>
.preview-token {
  width: 90vw;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: #00205f9c; */
  background-image: url("../../assets/hue.svg");
  /* background-image: url("../../assets/dashboard.svg"); */
}

.create-header {
  padding: 20px;
  color: var(--white-color);
}

.preview-body {
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.create-header {
  padding-top: 2%;
  padding-left: 50px;
}

.create-header > h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #ff3f7f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.create-header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.request-img {
  width: 100%;
  height: 300px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.1);
  position: relative;
  border: 2px solid var(--border-color);
}

.request_image {
  width: 70%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.request-title {
  margin: 0px;
  font-size: 50px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.request-description {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.request-asset-nature {
  margin-top: 20px;
}

.request-asset-nature > p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.asset-nature {
  padding: 10px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 0.5px solid #ff7f3f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature > p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 25px;
  letter-spacing: 0;
  margin-top: 10px;
  color: white;
  min-width: 20%;
  font-family: "Bebas Neue", cursive;
}

.btn-prev {
  background: transparent !important;
  border-radius: 10px;
  font-weight: 600 !important;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 25px;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  margin-top: 10px;
  border: 1px solid #ff3f7f;
  color: white;
  width: 20%;
}

.btn-designer {
  background: linear-gradient(
    101.24deg,
    #ff7f3f 43.86%,
    #ffbf3f 86.82%
  ) !important;
  border-radius: 15px;
  text-transform: none;
  font-weight: 700 !important;
  font-size: 22px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0;
  width: 30%;
  margin-top: 10px;
}

.btn-prev-designer {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 22px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0;
  width: 30%;
  margin-top: 10px;
  border: 1px solid var(--designer-color);
  color: var(--secondary-color);
}

.profile-store {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 5px;
}

.profile-image > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.name {
  font-size: 10px;
  font-weight: 400;
  color: #e8e8e8;
}

.username {
  font-size: 12px;
  font-weight: 400;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.verification-tab {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.623);
  padding: 5px;
  border-radius: 5px;
}

.status {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 16.2%,
    #ff3f7f 88.17%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5px;
}

.status-text {
  color: white;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; */
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
}

.btn-listed {
  background: #3aa959 !important;
  border-radius: 10px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 20%;
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  color: white !important;
  margin-right: 10px;
  cursor: auto;
}

.message-data {
  width: 40px;
  height: 40px;
  background: var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--border-color);
  margin-top: 10px;
}

.btn-secondary {
  color: white !important;
  background: transparent !important;
  border: 1px solid #eb5129;
  cursor: pointer !important;
  text-transform: none;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
  letter-spacing: 0.8px !important;
  border-radius: 15px;
  margin-left: 20px;
}
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.button-data {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}
.model-title {
  font-size: 20px !important;
  text-align: center;
}

.button-data-secondary {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}
.btn-quote {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 5px;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 20px;
  letter-spacing: 0;
  margin-top: 15px;
  color: white;
  width: 20%;
  font-family: "Bebas Neue", cursive;
}
.image-list {
  width: 80%;
  height: 300px;
  margin-top: -10px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}
.image-card {
  height: 85px;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  position: relative;
}

.image-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  visibility: inherit;
  position: relative;
}
.video-card {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 500;
}
</style>
