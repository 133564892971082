import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"500","transition":"dialog-bottom-transition"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"model-card text-center",attrs:{"color":'#15181E'}},[_c(VRow,{staticStyle:{"width":"100%"},attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"lg":"10"}},[_c('div',{class:'general-text-title model-title'},[_vm._v(" Ask Queries ")])]),_c(VCol,{staticClass:"mt-5",attrs:{"lg":"2"}},[_c(VIcon,{attrs:{"color":"white"},on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"sub-title mt-5"},[_vm._v(" You can ask any queries regarding this asset. We will get back to you at the earliest. ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"mt-2",attrs:{"rows":"5"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),_c(VBtn,{staticClass:"mt-10 button button-data",attrs:{"disabled":_vm.message.length === 0},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("Submit")])],1)],1),_c('Loader',{attrs:{"visible":_vm.isLoading,"text":_vm.loaderText}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }