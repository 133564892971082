<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" class="dialog" max-width="500">
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3 class="general-text-title">Rent Asset</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <v-icon color="white" @click="close()">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div class="card-body">
          <v-text-field outlined label="Hours to rent" v-model="days" type="number" class="mt-10">
          </v-text-field>
          <v-row justify="space-between">
            <v-col lg="6">
              <h4 class="pay-title">Payable Amount</h4>
            </v-col>
            <v-col lg="6">
              <h4 class="pay-title">
                <v-icon color="#ff7f3f" size="20">mdi-ethereum</v-icon>
                {{ tokenDetails.price }} ETH
              </h4>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col lg="6">
              <h4 class="pay-title">Amount * Hours</h4>
            </v-col>
            <v-col lg="6">
              <h4 class="pay-title">
                <v-icon color="#ff7f3f" size="20">mdi-ethereum</v-icon>
                {{ parseFloat(tokenDetails.price) * parseFloat(days) }} ETH
              </h4>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col lg="6">
              <h4 class="pay-title">Total Amount</h4>
            </v-col>
            <v-col lg="6">
              <h4 class="pay-title">
                <v-icon color="#ff7f3f" size="20">mdi-ethereum</v-icon>
                {{ parseFloat(tokenDetails.price) * parseFloat(days) + 0.0125 }}
                ETH
              </h4>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col lg="8"></v-col>
            <v-col lg="4">
              <v-btn class="mt-5 btn" @click="rentAsset()"> Rent Asset </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-dialog>
    <Loader :visible="loading" :text="loaderText"></Loader>
    <StatusModel :status="isSuccess" :title="statusTitle" :message="statusMessage" :visible="showStatus"></StatusModel>
  </div>
</template>
<script>
import { assetObj } from "../../services/asset-service";
import Loader from "../Loader/Loader.vue";
import StatusModel from "./StatusModel.vue";

export default {
  name: "RentAssetModal",
  props: ["visible", "tokenDetails"],
  computed: {},
  data() {
    return {
      days: 0,
      loading: false,
      loaderText: "Renting asset....",
      isSuccess: false,
      statusTitle: "",
      statusMessage: "",
      showStatus: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async rentAsset() {
      try {
        this.loading = true;
        const price =
          parseFloat(this.tokenDetails.price) * parseFloat(this.days) + 0.0125;
        await assetObj.rentNFT(this.tokenDetails.tokenId, price, this.days);
        this.loading = false;
        this.statusTitle = "Successfully rented asset";
        this.statusMessage = "You have successfully rented the asset";
        this.isSuccess = true;
        this.showStatus = true;
        setTimeout(() => {
          this.showStatus = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.statusTitle = "Error renting asset";
        this.statusMessage = error.message;
        this.isSuccess = false;
        this.showStatus = true;
        setTimeout(() => {
          this.showStatus = false;
        }, 2000);
      }
    },
  },
  components: { Loader, StatusModel },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
}

h3 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  font-weight: 900;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.v-text-field--outlined>>>fieldset {
  border-color: var(--border-color);
}

.pay-title {
  color: white;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  min-width: 15%;
  margin-top: 10px;
}
</style>
