<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="60%"
    >
      <div class="dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3 class="pl-6">Edit Uploaded Asset</h3>
            </v-col>
            <v-col lg="01" class="mt-4">
              <v-icon class="span" @click="close()" size="20">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <v-stepper
          class="ml-0 mr-0 stepper mt-5"
          width="100%"
          elevation="0"
          alt-labels
          v-model="stepIndex"
        >
          <v-stepper-header class="stepper-header">
            <v-stepper-step step="1" color="#ff7f3f" :complete="stepIndex > 1">
              <span
                :class="stepIndex >= 1 ? 'text-color-selected' : 'text-color'"
                >Asset Details</span
              >
            </v-stepper-step>
            <v-divider
              :class="stepIndex > 1 ? 'divider-complete' : 'divider'"
            ></v-divider>
            <v-stepper-step step="2" color="#ff7f3f" :complete="stepIndex > 2">
              <span
                :class="stepIndex >= 2 ? 'text-color-selected' : 'text-color'"
                >Asset Env</span
              >
            </v-stepper-step>
            <v-divider
              :class="stepIndex > 2 ? 'divider-complete' : 'divider'"
            ></v-divider>
            <v-stepper-step step="3" color="#ff7f3f" :complete="stepIndex > 3">
              <span
                :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'"
                >Asset Category</span
              >
            </v-stepper-step>
            <v-divider
              :class="stepIndex > 3 ? 'divider-complete' : 'divider'"
            ></v-divider>
            <v-stepper-step step="4" color="#ff7f3f" :complete="stepIndex > 4">
              <span
                :class="stepIndex >= 4 ? 'text-color-selected' : 'text-color'"
                >Asset Media</span
              >
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="form">
                <v-row>
                  <v-col lg="12">
                    <v-row>
                      <v-col lg="12">
                        <div :class="'form-group'">
                          <p>Asset Name *</p>
                          <input type="text" v-model="tokenDetails.name" />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="12">
                        <div :class="'form-group'">
                          <p>Asset Description *</p>
                          <textarea
                            type="text"
                            v-model="tokenDetails.description"
                            maxlength="500"
                          ></textarea>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="button-footer">
                  <v-btn
                    :class="'button btn'"
                    @click="changeIndex(2)"
                    :disabled="
                      tokenDetails.placeholder.length === 0 ||
                      tokenDetails.name.length === 0 ||
                      tokenDetails.description.length === 0
                    "
                    >Next</v-btn
                  >
                  <!-- <v-btn :class="'button btn'" @click="changeIndex(2)">Next</v-btn> -->
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="form">
                <v-row>
                  <v-col lg="8">
                    <div :class="'form-group'">
                      <p>Asset Environment *</p>
                      <v-select
                        dark
                        v-model="tokenDetails.selectedAssetType"
                        :items="assetTags"
                        :class="'select-type'"
                        color="white"
                        background-color="transparent"
                        multiple
                        solo
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
                <div class="button-footer-mult">
                  <v-btn :class="'button btn-prev'" @click="changeIndex(1)"
                    >Previous</v-btn
                  >
                  <v-btn
                    :class="'button btn'"
                    @click="changeIndex(3)"
                    :disabled="tokenDetails.selectedAssetType.length === 0"
                    >Next</v-btn
                  >
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="form">
                <v-row>
                  <v-col lg="8">
                    <div :class="'form-group'">
                      <p>Asset Category *</p>
                      <v-select
                        dark
                        v-model="tokenDetails.assetCategories"
                        :items="assetCategories"
                        :class="'select-type'"
                        color="white"
                        background-color="transparent"
                        solo
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1" class="chip">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ tokenDetails.assetCategories.length - 1 }}
                            others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                    <div :class="'form-group'">
                      <p>File Formats *</p>
                      <v-select
                        dark
                        v-model="tokenDetails.availableFileFormats"
                        :items="assetFileFormat"
                        :class="'select-type'"
                        color="white"
                        background-color="transparent"
                        solo
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 4" class="chip">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span
                            v-if="index === 4"
                            class="grey--text text-caption"
                          >
                            (+{{ tokenDetails.availableFileFormats.length - 4 }}
                            others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <div class="button-footer-mult">
                  <v-btn :class="'button btn-prev'" @click="changeIndex(2)"
                    >Previous</v-btn
                  >
                  <v-btn
                    :class="'button btn'"
                    @click="changeIndex(4)"
                    :disabled="tokenDetails.selectedAssetType.length === 0"
                    >Next</v-btn
                  >
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="form-group">
                <p>Upload Images</p>
                <span
                  >Adding more images helps to get your asset identified</span
                >
                <v-row class="mt-2">
                  <v-col
                    lg="2"
                    v-for="(image, index) in tokenDetails.references"
                    :key="index"
                  >
                    <div class="reference-image">
                      <img :src="image" />
                    </div>
                  </v-col>
                </v-row>
                <div class="input mt-2">
                  <p
                    style="margin: 0px; padding: 0px; font-size: 16px"
                    class="mt-2"
                    v-if="
                      tokenDetails.references === undefined ||
                      tokenDetails.references.length === 0
                    "
                  >
                    No File Choosen (Tap to choose)
                  </p>
                  <input
                    type="file"
                    class="mt-2 field"
                    accept="image/*"
                    @change="onMultipleImageChange"
                    multiple
                    style="opacity: 0"
                  />
                </div>
              </div>
              <div class="form-group mt-10">
                <p>Upload Video</p>
                <span class="mb-5"
                  >Adding animation helps in listing your asset</span
                >
                <v-row class="mt-2">
                  <v-col
                    lg="2"
                    v-for="(video, index) in tokenDetails.videos"
                    :key="index"
                  >
                    <div class="reference-image">
                      <video :poster="tokenDetails.placeholder" controls>
                        <source :src="video" />
                      </video>
                    </div>
                  </v-col>
                </v-row>
                <div class="input mt-2">
                  <p
                    style="margin: 0px; padding: 0px; font-size: 16px"
                    class="mt-2"
                    v-if="
                      tokenDetails.references === undefined ||
                      tokenDetails.references.length === 0
                    "
                  >
                    No File Choosen (Tap to choose)
                  </p>
                  <input
                    type="file"
                    class="mt-2 field"
                    @change="onMultipleVideoChange"
                    accept="video/mp4,video/x-m4v,video/*"
                    multiple
                    style="opacity: 0"
                  />
                </div>
              </div>
              <div class="button-footer-mult">
                <v-btn :class="'button btn-prev'" @click="changeIndex(3)"
                  >Previous</v-btn
                >
                <v-btn
                  :class="'button btn'"
                  @click="updateAsset()"
                  :disabled="
                    tokenDetails.availableFileFormats.length === 0 ||
                    tokenDetails.assetCategories.length === 0
                  "
                  >Update</v-btn
                >
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-dialog>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :status="isSuccess"
      :title="statusTitle"
      :message="statusMessage"
      :visible="successPopup"
      @close="closeModal"
    ></StatusModel>
    <EditPriceModal
      :visible="editPriceModal"
      @close="closeModal()"
      :tokenDetails="tokenDetails"
    ></EditPriceModal>
  </div>
</template>
<script>
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import { mapState } from "vuex";
import {
  CATEGORIES,
  AVAILABLE_FILE_TYPES,
  PROJECT_INTERESTED,
} from "../../helpers/constants";
import { assetObj } from "../../services/asset-service";
import EditPriceModal from "./UploadAssets/EditPriceModal.vue";
import { uploadFile } from "../../helpers/file-upload";

export default {
  name: "EditUploadedAsset",
  props: ["visible", "tokenDetails"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      isLoading: false,
      loaderText: "Updating Asset...",
      isSuccess: false,
      successPopup: false,
      statusTitle: "",
      statusMessage: "",
      stepIndex: 1,
      assetTags: ["Metaverse", "Gaming", "AR/VR"],
      assetTypes: [
        "Avatar/Character",
        "Character",
        "Environment",
        "Objects",
        "GamingZone",
      ],
      assetFileFormat: [".gltf", ".glb", ".fbx", ".png", ".svg"],
      assetCategories: CATEGORIES,
      editPriceModal: false,
      MAX_FILE_SIZE: 2 * 1024 * 1024,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeIndex(index) {
      this.stepIndex = index;
    },
    async updateAsset() {
      try {
        this.loaderText = "Updating Asset...";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        await assetObj.updateLazyMint(tokenId, this.tokenDetails);
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Updation Successful";
        this.statusMessage = "Succesfully updated asset details";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
          this.close();
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Updating Asset Details";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    closeModal() {
      this.successPopup = false;
      this.editPriceModal = false;
    },
    openModal() {
      this.editPriceModal = true;
    },
    async onMultipleImageChange(event) {
      const files = event.target.files;
      if (files) {
        if (this.tokenDetails.references === undefined) {
          this.tokenDetails.references = [];
        }
        this.loaderText = "Uploading Files...";
        this.isLoading = true;
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          const size = files[index].size;
          if (size > this.MAX_FILE_SIZE) {
            this.isSuccess = false;
            this.statusTitle = "File Size Exceeded";
            this.statusMessage =
              "One of the file size is exceeds the max size of 2MB per file";
            this.successPopup = true;
            setTimeout(() => {
              this.successPopup = false;
              this.isLoading = false;
              return;
            }, 5000);
          }
          const url = await uploadFile(
            element,
            `uploads/${this.user.uid}/references/`
          );
          this.tokenDetails.references.push(url);
        }
        this.isLoading = false;
      }
    },
    async onMultipleVideoChange(event) {
      const files = event.target.files;
      if (files) {
        if (this.tokenDetails.videos === undefined) {
          this.tokenDetails.videos = [];
        }
        this.loaderText = "Uploading Files...";
        this.isLoading = true;
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          const size = files[index].size;
          if (size > this.MAX_FILE_SIZE) {
            this.isSuccess = false;
            this.statusTitle = "File Size Exceeded";
            this.statusMessage =
              "One of the file size is exceeds the max size of 2MB per file";
            this.successPopup = true;
            setTimeout(() => {
              this.successPopup = false;
              this.isLoading = false;
              return;
            }, 5000);
          }
          const url = await uploadFile(
            element,
            `uploads/${this.user.uid}/videos/`
          );
          this.tokenDetails.videos.push(url);
        }
        this.isLoading = false;
      }
    },
  },
  components: {
    Loader,
    StatusModel,
    EditPriceModal,
  },
};
</script>
<style scoped>
.dialog {
  background: var(--primary-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 78.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 40px;
  font-weight: 500;
  font-family: "Orbitron", sans-serif !important;
}

.stepper-header {
  padding-right: 5% !important;
  margin-left: -4% !important;
}

.v-stepper__content {
  padding: 15px !important;
}

.text-color {
  color: transparent !important;
}

.text-color-selected {
  color: white !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.theme--light.v-stepper {
  background: transparent !important;
}

.divider {
  border-color: grey !important;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 5px;
}

.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 400;
}

.form-group > span {
  margin-bottom: 20px !important;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 400;
}

.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.button-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 50px;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  color: white !important;
  font-weight: 400 !important;
  font-size: 25px;
  width: 15%;
  font-family: "Bebas Neue", cursive !important;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  color: white;
  font-weight: 400 !important;
  font-size: 20px;
  width: 15%;
  border: 1px solid var(--border-color) !important;
  font-family: "Bebas Neue", cursive !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}
.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}
.reference-image {
  width: 100px;
  height: 100px;
  position: relative;
  border: 1px solid #ff3f7f;
  border-radius: 5px;
}
.reference-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.reference-image > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  position: relative;
}
.field{
  position: absolute;
  top: 0;
}
</style>
