import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{staticClass:"dialog",attrs:{"transition":"dialog-bottom-transition","persistent":"","max-width":"40%"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"dialog"},[_c('div',{staticClass:"card-header"},[_c(VRow,[_c(VCol,{attrs:{"lg":"11"}},[_c('h3',{staticClass:"pl-6"},[_vm._v("Quote Price")])]),_c(VCol,{staticClass:"mt-4",attrs:{"lg":"01"}},[_c(VIcon,{staticClass:"span",attrs:{"size":"20"},on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"mb-10"},[_vm._v(" Add a quotation for your asset so it can show up on your profile along with the other asset details ")]),_c('span',{staticClass:"mt-10",staticStyle:{"margin-bottom":"30px","margin-left":"0px","color":"white","font-size":"16px","font-weight":"400"}},[_vm._v("Rate in US Dollars")]),_c(VTextField,{staticClass:"text-field",attrs:{"prepend-icon":"mdi-currency-usd","solo":"","color":"#ff7f3f","height":"50","background-color":"transparent","placeholder":"Rate in US Dollars","type":"number","dark":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c(VRow,{staticStyle:{"width":"100%","justify-content":"center"}},[_c(VCol,{attrs:{"lg":"4"}}),_c(VCol,{attrs:{"lg":"4"}}),_c(VCol,{attrs:{"lg":"4"}},[_c(VBtn,{staticClass:"button btn ml-5 mt-10",on:{"click":function($event){return _vm.updateAsset()}}},[_vm._v("Submit")])],1)],1)],1)])]),_c('Loader',{attrs:{"text":_vm.loaderText,"visible":_vm.isLoading}}),_c('StatusModel',{attrs:{"status":_vm.isSuccess,"title":_vm.statusTitle,"message":_vm.statusMessage,"visible":_vm.successPopup},on:{"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }