<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="40%"
    >
      <div class="dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3 class="pl-6">Quote Price</h3>
            </v-col>
            <v-col lg="01" class="mt-4">
              <v-icon class="span" @click="close()" size="20">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div class="card-body">
          <div class="form-group">
            <p class="mb-10">
              Add a quotation for your asset so it can show up on your profile
              along with the other asset details
            </p>
            <span
              class="mt-10"
              style="
                margin-bottom: 30px;
                margin-left: 0px;
                color: white;
                font-size: 16px;
                font-weight: 400;
              "
              >Rate in US Dollars</span
            >
            <v-text-field
              class="text-field"
              prepend-icon="mdi-currency-usd"
              solo
              color="#ff7f3f"
              height="50"
              background-color="transparent"
              v-model="price"
              placeholder="Rate in US Dollars"
              type="number"
              dark
            ></v-text-field>
          </div>
          <v-row style="width: 100%; justify-content: center">
            <v-col lg="4"></v-col>
            <v-col lg="4"></v-col>
            <v-col lg="4">
              <v-btn class="button btn ml-5 mt-10" @click="updateAsset()"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-dialog>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :status="isSuccess"
      :title="statusTitle"
      :message="statusMessage"
      :visible="successPopup"
      @close="closeModal"
    ></StatusModel>
  </div>
</template>
<script>
import { assetObj } from "../../../services/asset-service";
import Loader from "../../Loader/Loader.vue";
import StatusModel from "../../Modals/StatusModel.vue";
import { notificationObj } from "../../../services/notification-service";
import {
  ADMIN_USER_ID,
  NOTIFICATION_ASSET_PRICE_UPDATE,
} from "../../../helpers/constants";

export default {
  name: "EditPriceModal",
  props: ["visible", "tokenDetails"],
  data() {
    return {
      isLoading: false,
      loaderText: "",
      successPopup: false,
      statusTitle: "",
      statusMessage: "",
      isSuccess: false,
      price: this.tokenDetails.price === 0 ? "" : this.tokenDetails.price,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async updateAsset() {
      try {
        this.loaderText = "Updating Asset...";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        this.tokenDetails.price = this.price;
        await assetObj.updateLazyMint(tokenId, this.tokenDetails);
        const notificationData = {
          title: `${this.tokenDetails.user.name} - updated their asset price`,
          message: `${this.tokenDetails.user.name} - updated their asset price to $ ${this.tokenDetails.price}`,
          meta: {
            request_id: "",
            chat: true,
            asset_id: `${tokenId}`,
          },
        };
        await notificationObj.sendNotification(
          notificationData,
          this.tokenDetails.userId,
          ADMIN_USER_ID,
          NOTIFICATION_ASSET_PRICE_UPDATE
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Updation Successful";
        this.statusMessage = "Succesfully updated asset details";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
          this.close();
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Updating Asset Details";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    closeModal() {},
  },
  components: {
    Loader,
    StatusModel,
  },
};
</script>
<style scoped>
.dialog {
  background: var(--primary-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 78.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 40px;
  font-weight: 500;
  font-family: "Orbitron", sans-serif !important;
}
.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}
.form-group {
  padding-left: 20px;
  margin-bottom: 5px;
  margin-top: 5%;
}

.form-group > p {
  margin-bottom: 10px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  color: white !important;
  font-weight: 400 !important;
  font-size: 25px;
  width: 100%;
  font-family: "Bebas Neue", cursive !important;
  margin-top: 5%;
}
</style>
